<template>
	<div style="z-index:10000; position: fixed; top: 0; left: 0; width: 100%;">
		<RVReady v-if="isOpen && new Date() < new Date(1620017999000) /* Sun May 02 2021 23:59:59 GMT-0500 (Central Daylight Time) */" @close="close" @navigate="navigate" />
	</div>
</template>

<script>
	import RVReady from '@/components/RVReady2021'

	let opening

	export default {
		components: {
			RVReady,
		},

		created() {
			opening = setTimeout(() => {
				this.isOpen = true
			}, 3000)

			if (window.location.href.includes('?no-popup')) {
				clearTimeout(opening)
				this.close()
				
				if (window.history.replaceState) {
					window.history.replaceState({
						path: window.location.href.split('?')[0]
					})
				}
			}
		},
		
		data() {
			return {
				isOpen: false,
			}
		},

		methods: {
			close() {
				this.isOpen = false

				const iframe = document.createElement('iframe')
				iframe.src = 'https://popup.dishformyrv.com/loader.html?close'
				iframe.setAttribute('style', 'display:none!important;')

				document.body.appendChild(iframe)
			},

			navigate(destination) {
				const listener = window.addEventListener('message', event => {
					if (event.origin === 'https://popup.dishformyrv.com' && event.data === 'close') {
						window.location.href = `${destination}${(new URL(destination)).search === '' ? '?' : '&'}no-popup`
						window.removeEventListener('message', listener)
					}
				})

				const iframe = document.createElement('iframe')
				iframe.src = 'https://popup.dishformyrv.com/loader.html?close'
				iframe.setAttribute('style', 'display:none!important;')

				document.body.appendChild(iframe)
			},
		},
	}
</script>

<style>
	html {
		font-size: 16px!important;
	}
</style>
